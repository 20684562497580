<template>
  <iframe
    :src="presentationUrl"
    frameborder="0"
    class="iframe"
    allowfullscreen="true"
    mozallowfullscreen="true"
    webkitallowfullscreen="true"
  ></iframe>
</template>

<script>
export default {
  props: ["url"],
  data() {
    return {
      presentationUrl: this.url,
    };
  },
};
</script>

<style>
iframe {
  display: block;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
}
</style>